<template>
  <div>
    <b-row>
      <b-col md="12">
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t("labels.show") }}</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  @input="fetchData"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t("labels.entries") }}</label>
              </b-col>

              <b-col
                md="6"
                class="d-flex align-items-center justify-content-center mb-1"
              >
                <b-form-input
                  v-model="searchQuery"
                  @input="fetchData"
                  class="d-inline-block mr-1"
                  :placeholder="$t('labels.search')"
                />
                <div>
                  <b-button
                    v-if="isSeePermission({ permission: 'exclusion-list-add' })"
                    variant="primary"
                    class="d-flex align-items-center justify-content-center"
                    @click="addExcludedUser()"
                  >
                    <feather-icon icon="UserPlusIcon" size="16" class="mr-50" />
                    <span class="text-nowrap">{{ $t("buttons.add") }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>

          <b-overlay
            :show="loadings.overlay"
            :variant="$store.state.appConfig.layout.skin"
            blur="2"
            spinner-type="grow"
            class="p-50"
            opacity="0.50"
          >
            <b-table
              style="min-height: 300px"
              ref="refUserListTable"
              :items="exclusionList"
              responsive
              :fields="tableColumns"
              primary-key="_id"
              :sort-by.sync="sortBy"
              show-empty
              :empty-text="$t('messages.nomatching')"
              :sort-desc.sync="isSortDirDesc"
            >
              <!-- Column: type -->
              <template #cell(type)="data">
                <div class="d-flex justify-content-center">
                  <b-badge :variant="getVariantColorByType(data.item.type)">
                    {{ getAbvType(data.item.type) }}
                  </b-badge>
                </div>
              </template>

              <template #cell(actions)="data">
                <div class="d-flex">
                  <b-button
                    variant="primary"
                    class="btn-icon rounded-circle mr-1 mb-1"
                    v-b-tooltip.hover.top="$t('tooltips.detail')"
                    @click="showDetailExcludeUser(data.item)"
                    size="sm"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>

                  <b-button
                    v-if="isSeePermission({ permission: 'exclusion-list-edit' })"
                    variant="primary"
                    class="btn-icon rounded-circle mr-1 mb-1"
                    v-b-tooltip.hover.top="$t('tooltips.edit')"
                    @click="editExcludedUser(data.item)"
                    size="sm"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>

                  <b-button
                    v-if="
                    isSeePermission({ permission: 'exclusion-list-remove' })
                  "
                    variant="primary"
                    class="btn-icon rounded-circle mr-1 mb-1"
                    v-b-tooltip.hover.top="$t('tooltips.delete')"
                    @click="removeExcludedUser(data.item)"
                    size="sm"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-overlay>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >{{ $t("labels.showing") }} {{ dataMeta.from }}
                  {{ $t("labels.to") }} {{ dataMeta.to }} {{ $t("labels.of") }}
                  {{ dataMeta.of }} {{ $t("labels.entries") }}</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @input="fetchData"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <ExclusionListAdd ref="exclusionListAddComponent" @fetch-data="fetchData" />

    <ExclusionListEdit
      ref="exclusionListEditComponent"
      @fetch-data="fetchData"
    />

    <ExclusionListDetail ref="exclusionListDetailComponent" />
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { computed, onMounted, onUnmounted, reactive, ref } from '@vue/composition-api'
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import ExclusionListAdd from "@/views/pages/exclusionList/exclusionListAdd.vue";
import store from "@/store";
import exclusionListStoreModule from "@/views/pages/exclusionList/exclusionListStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import ExclusionListEdit from "@/views/pages/exclusionList/exclusionListEdit.vue";
import Swal from "sweetalert2";
import ExclusionListDetail from "@/views/pages/exclusionList/exclusionListDetail.vue";
import { useUtils } from "@core/libs/acl";

const { t } = useI18nUtils();
import { translatableText } from '@core/utils/utils'
import { axiosErrorHandle } from '@core/utils/errorHandler'
const { isSeePermission } = useUtils();
import { getCurrentInstance } from '@vue/composition-api'

export default {
  name: "index",
  components: {
    ExclusionListDetail,
    ExclusionListEdit,
    ExclusionListAdd,
    BFormInput,
    BCard,
    BButton,
    BMedia,
    vSelect,
    BCol,
    BLink,
    BPagination,
    BOverlay,
    BTable,
    BRow,
    BBadge,
  },
  setup() {
    //It is necessary to add this because in asynchronous methods you
    //cannot use the getCurrentInsance to translate since it is converted to null
    const vm = getCurrentInstance()

    const EXCLUSION_LIST_APP_STORE_MODULE_NAME = "app-exclusion-list";
    if (!store.hasModule(EXCLUSION_LIST_APP_STORE_MODULE_NAME))
      store.registerModule(
        EXCLUSION_LIST_APP_STORE_MODULE_NAME,
        exclusionListStoreModule
      );
    onUnmounted(() => {
      if (store.hasModule(EXCLUSION_LIST_APP_STORE_MODULE_NAME))
        store.unregisterModule(EXCLUSION_LIST_APP_STORE_MODULE_NAME);
    });

    const toast = useToast(ToastificationContent);

    const sortBy = ref("userName");
    const perPage = ref(10);
    const perPageOptions = ref([10, 25, 50, 100]);
    const isSortDirDesc = ref(false);
    const dataMeta = ref({
      from: 0,
      to: 0,
      of: 0,
    });
    const totalRows = ref(0);
    const currentPage = ref(1);

    const loadings = reactive({
      overlay: false,
    });

    const tableColumns = computed( ()=> [
      { key: "userDni", sortable: true, label: t('ID_blocked') },
      { key: "userName", label: t('labels.username') },
      { key: "actions", label: t("labels.actions") },
    ]);

    const exclusionList = ref([]);

    const searchQuery = ref("");

    const exclusionListAddComponent = ref(null);
    const exclusionListEditComponent = ref(null);
    const exclusionListDetailComponent = ref(null);

    const addExcludedUser = () => {
      exclusionListAddComponent.value.setModal(true);
    };

    const editExcludedUser = (data) => {
      exclusionListEditComponent.value.setEditData(data);
      exclusionListEditComponent.value.setModal(true);
    };

    const showDetailExcludeUser = (data) => {
      exclusionListDetailComponent.value.setDetailData(data);
      exclusionListDetailComponent.value.setModal(true);
    };

    const removeExcludedUser = async (data) => {
      try {
        const confirm = await Swal.fire({
          title:  vm.proxy.$t('remove_lock'),
          text: vm.proxy.$t('are_sure_perform_operation'),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: vm.proxy.$t('buttons.yes'),
          cancelButtonText: vm.proxy.$t('buttons.no'),
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary mr-1",
            cancelButton: "btn btn-danger ml-1",
          },
        });
        if (confirm.value) {
          await store.dispatch("app-exclusion-list/removeExcludeUser", data._id);
           fetchData();
        }
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: await translatableText({
              text : axiosErrorHandle(error)
            }),
            icon: "XIcon",
            variant: "danger",
          },
        });
      }
    };

    const getVariantColorByType = (type) => {
      let rType = "primary";
      if (type === "UserDni") rType = "danger";
      if (type === "Country") rType = "info";
      if (type === "UserFullName") rType = "success";
      if (type === "Device") rType = "primary";
      return rType;
    };

    const getAbvType = (type) => {
      let rAbvType = "";
      if (type === "UserDni") rAbvType = "DNI";
      if (type === "Country") rAbvType = "COUNTRY";
      if (type === "UserFullName") rAbvType = "NAME";
      if (type === "Device") rAbvType = "DEVICE";
      return rAbvType;
    };

    const fetchData = () => {
      loadings.overlay = true;

      const params = {
        // sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        perPage: perPage.value,
        page: currentPage.value,
        q: searchQuery.value,
      };
      store
        .dispatch("app-exclusion-list/fetchExcludeUsers", params)
        .then((response) => {
          const localItemsCount = response.data.data
            ? response.data.data.length
            : 0;
          loadings.overlay = false;
          exclusionList.value = response.data.data;
          totalRows.value = response.data.total;
          perPage.value = response.data.perPage;
          currentPage.value = response.data.page;
          dataMeta.value.from =
            response.data.perPage *
            (response.data.page - 1) *
            (localItemsCount ? 1 : 0);
          dataMeta.value.to =
            response.data.perPage * (response.data.page - 1) + localItemsCount;
          dataMeta.value.of = totalRows.value;
        })
        .catch(async (error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: await translatableText({
                text: axiosErrorHandle(error)
              }),
              icon: "XIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          loadings.overlay = false;
        });
    };

    //hooks
    onMounted(() => {
      fetchData();
    });

    return {
      tableColumns,
      exclusionList,
      sortBy,
      perPage,
      perPageOptions,
      isSortDirDesc,
      searchQuery,
      dataMeta,
      totalRows,
      currentPage,
      editExcludedUser,
      removeExcludedUser,
      addExcludedUser,
      exclusionListAddComponent,
      exclusionListDetailComponent,
      exclusionListEditComponent,
      fetchData,
      getVariantColorByType,
      loadings,
      getAbvType,
      showDetailExcludeUser,
      isSeePermission,
    };
  },

  data() {
    return {};
  },
};
</script>

<style scoped></style>

<template>
  <b-modal
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    :title="$t('labels.detail')"
    shadow
    backdrop
    no-close-on-backdrop
    hide-footer
    centered
    size="md"
    @hidden="setModal(false)"
    @ok="setModal(false)"
  >
    <template #default="{ hide }">
      <div>
        <b-row>
          <b-col>
            <!-- userFullName -->
            <validation-provider
              #default="validationContext"
              name="userFullName"
            >
              <b-form-group label-for="userFullName">
                <template #label> {{$t('name_surname')}}  </template>
                <b-form-input v-model="userData.userFullName" trim readonly />
              </b-form-group>
            </validation-provider>
            <!-- dni -->
            <validation-provider #default="validationContext" name="DNI">
              <b-form-group label-for="DNI">
                <template #label> {{$t('labels.dni')}} </template>
                <b-form-input v-model="userData.userDni" trim readonly />
              </b-form-group>
            </validation-provider>
            <!-- description -->
            <validation-provider
              #default="validationContext"
              name="description"
            >
              <b-form-group label-for="description">
                <template #label> {{$t('labels.description')}}</template>
                <b-form-input v-model="userData.description" trim readonly />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="setModal(false)"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <feather-icon icon="XIcon" class="mr-1" />
            {{ $t("labels.close") }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
  name: "exclusionListDetail",
  components: {
    vSelect,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false);

    const userData = ref({
      userFullName: "",
      userDni: "",
      description: "",
      _id: "",
    });

    const setModal = (flag) => {
      isAddNewUserSidebarActive.value = flag;
    };
    const setDetailData = (data) => {
      userData.value = Object.assign({}, data);
    };

    return {
      setModal,
      isAddNewUserSidebarActive,
      userData,
      setDetailData,
    };
  },
};
</script>

<style scoped></style>

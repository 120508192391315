import axios from "@axios";
const APP_URL = process.env.VUE_APP_URL;
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchExcludeUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/exclusion-list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createExcludeUsers(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APP_URL}/exclusion-list`, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editExcludeUser(ctx, { id, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${APP_URL}/exclusion-list/${id}`, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeExcludeUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
            .delete(`${APP_URL}/exclusion-list/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
  },
};
